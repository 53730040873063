<template>
    <div class="box my-account myCoupon">
        <div class="myCoupon_open">
            <el-button type="primary" @click="openCoupon">输入券码激活</el-button>
            <el-button type="primary" v-if="page.receive_status == '0'" @click="activationCouponCode('all')">批量激活
            </el-button>
        </div>
        <div class="myCoupon_table">
            <el-tabs v-model="page.receive_status" type="card" @tab-click="handleClick">
                <el-tab-pane label="待激活" name="0"></el-tab-pane>
                <el-tab-pane label="已激活" name="1"></el-tab-pane>
            </el-tabs>
            <el-table :data="couponDataList" @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="55" v-show="page.receive_status == '0'"></el-table-column>
                <el-table-column label="消费券面值" prop="face_price" width="120"></el-table-column>
                <el-table-column label="消费券号" prop="voucher_number" width="220"></el-table-column>
                <el-table-column label="激活码" prop="voucher_pwd" width="110px"></el-table-column>
                <el-table-column label="消费券余额" prop="balance" width="120"></el-table-column>
                <el-table-column label="激活时间" prop="receive_time"></el-table-column>
                <el-table-column label="激活状态" prop="receive_status_name" width="100"></el-table-column>
                <el-table-column label="领取类型" prop="get_type_name" width="100"></el-table-column>
                <el-table-column prop="date" label="操作" width="100" v-if="page.receive_status == '0'">
                    <template slot-scope="scope">
                        <el-button class="layui-btn layui-btn-xs" @click="activationCouponCode(scope.row)">立即激活
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="myCoupon_page">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="page.page"
                :page-size="page.page_size" layout="total, prev, pager, next" :total="pageTotal">
            </el-pagination>
        </div>

        <el-dialog title="激活消费券" :visible.sync="activationCoupon" width="500px" :before-close="handleClose">
            <div>
                <el-form :model="activeCouponData" label-width="190px" :rules="rules" ref="activeCouponData">
                    <el-form-item label="爱心消费帮扶券券号：" prop="voucher_number">
                        <el-input v-model.trim="activeCouponData.voucher_number" placeholder="券号是18位数字"></el-input>
                    </el-form-item>
                    <el-form-item label="爱心消费帮扶券激活码：" prop="voucher_pwd">
                        <el-input v-model.trim="activeCouponData.voucher_pwd" placeholder="激活码是8位数字"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="activationCoupon = false">取 消</el-button>
                <el-button type="primary" @click="subCoupon('activeCouponData')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="验证码激活消费券" :visible.sync="activationCouponCodeShow" :before-close="closeActivationCouponCode">
            <div class="batchIssueCouponBox">
                <el-row style="margin-top:20px;padding-bottom: 20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark">
                            <div>
                                <span style="display: inline-block">短信动态码： </span>
                                <div style="display: inline-block;width: 40%">
                                    <el-input v-model="mobileCode" placeholder="请输入短信验证码">
                                        <div style="margin: 0;" slot="append">
                                            <span class="verification" v-if="showTime" @click="openCode">获取动态码</span>
                                            <span v-else>{{ yzmTime }}秒后重试</span>
                                        </div>
                                    </el-input>
                                </div>
                            </div>
                            <div slot="footer" class="dialog-footer" style="text-align: right">
                                <el-button @click="closeActivationCouponCode">关 闭</el-button>
                                <el-button type="primary" @click="saveActivationCouponCode">确 定</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { voucherVoucherList, VoucherreceiveVoucher, activateByCode } from "@/api/member/member"
import { sendSmsCode } from "@/api/member/security";
import { mapGetters } from "vuex"

export default {
    name: "myCoupon",
    data() {
        return {
            smsCodeKey: '',
            yzmTime: 60,
            showTime: true,
            mobileCode: '',
            voucher_Id: [],
            multipleSelection: [],
            activationCouponCodeShow: false,
            couponDataList: [],
            page: {
                receive_status: '0',
                page: 1,
                page_size: 10
            },
            pageTotal: 0,
            activationCoupon: false,
            activeCouponData: {
                voucher_number: "",
                voucher_pwd: "",
            },
            rules: {
                voucher_number: [
                    { required: true, message: '请输入爱心消费帮扶券券号', trigger: 'blur' },
                ],
                voucher_pwd: [
                    { required: true, message: '请输入爱心消费帮扶券密码', trigger: 'blur' },
                ],
            }
        }
    },
    computed: {
        ...mapGetters(["member"]),
    },
    mounted() {
        this.voucherVoucherListData() // 用户领取的消费券
    },
    methods: {
        handleClick(tab, event) {
            this.page.page = 1;
            this.page.activatStatus = tab.name;
            this.voucherVoucherListData();
        },
        subCoupon(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.VoucherreceiveVoucherData()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        closeActivationCouponCode() {
            this.activationCouponCodeShow = false
            this.mobileCode = ''
            this.voucher_Id = []
            this.showTime = true
            this.yzmTime = 60
            this.$refs.multipleTable.clearSelection();
        },
        activationCouponCode(item) {
            if (item == 'all') {
                if (this.multipleSelection.length > 0) {
                    this.activationCouponCodeShow = true
                    this.multipleSelection.filter(item => { this.voucher_Id.push(item.id) })
                } else {
                    this.$message({
                        message: "请选择券码",
                        type: "warning"
                    })
                    return
                }
            } else {
                this.activationCouponCodeShow = true
                this.voucher_Id.push(item.id)
            }
        },
        saveActivationCouponCode() {
            if (this.mobileCode == '') {
                this.$message.error("请输入短信验证码！")
                return
            }
            var data = {
                v_ids: this.voucher_Id,
                key: this.smsCodeKey,
                auth_code: this.mobileCode
            }
            activateByCode(data)
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success("激活成功!")
                        this.closeActivationCouponCode()
                        this.voucherVoucherListData()
                    } else {
                        this.flag = false
                        this.$message({ message: res.message, type: "warning" })
                    }
                })
                .catch(err => {
                    this.flag = false
                    this.$message.error(err.message)
                })
        },
        // 发送验证码
        openCode() {
            let data = {
                mobile: this.member.mobile,
            }
            sendSmsCode(data).then(res => {
                let data = res.data
                this.showTime = false
                this.smsCodeKey = data.key
                let time = setInterval(() => {
                    this.yzmTime = this.yzmTime - 1
                    if (this.yzmTime <= 0) {
                        clearInterval(time)
                        this.yzmTime = 60
                        this.showTime = true
                    }
                }, 1000)
            }).catch(e => {
                this.$message.error(e.message)
            })
        },
        VoucherreceiveVoucherData() {
            VoucherreceiveVoucher(this.activeCouponData).then(res => {
                this.$message.success('激活成功！')
                this.activationCoupon = !this.activationCoupon
                this.voucherVoucherListData()
                this.activeCouponData.voucher_number = ''
                this.activeCouponData.voucher_pwd = ''
            }).catch(e => {
                this.$message.error(e.message)
            })
        },
        // 点击打开弹窗
        openCoupon() {
            this.activationCoupon = !this.activationCoupon
        },
        // 关闭弹窗事件
        handleClose(done) {
            this.resetForm('activeCouponData')
            done()
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleCurrentChange(val) {
            this.page.page = val
            this.voucherVoucherListData()
            window.scrollTo(0, 0)
            // console.log(`当前页: ${val}`)
        },
        // 用户领取的消费券
        voucherVoucherListData() {
            voucherVoucherList(this.page).then(res => {
                let data = res.data
                this.couponDataList = data.list
                this.pageTotal = data.count
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style scoped type="text/scss" lang="scss">
.my-account {
    background: #ffffff;
    padding: 20px;
}

.myCoupon_table {
    margin-top: 10px
}

.my-account .el-dialog__body {
    padding: 0px 20px 20px;
}

.layui-btn-xs {
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    font-size: 12px;
    margin-bottom: 5px;
}

.myCoupon {
    &_open {}
}
</style>
